'use client';

import { PropsWithChildren } from 'react';
import { Box } from '@wishket/design-system';
import { usePathname } from 'next/navigation';

import { GNB } from '@/widgets';
import { DialogProvider, UnAuthGuardProvider } from '@/shared/providers';
import { PATH } from '@/shared/constants';

const UnAuthorizedLayout = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const isSignPage =
    pathname.startsWith(PATH.LOGIN) || pathname.startsWith(PATH.SIGNUP);

  return (
    <UnAuthGuardProvider>
      <DialogProvider>
        <Box className={`${isSignPage && 'desktop:hidden'}`}>
          <GNB />
        </Box>
        {children}
      </DialogProvider>
    </UnAuthGuardProvider>
  );
};

export default UnAuthorizedLayout;
